import { ContextService } from 'src/app/shared/services/context.service';
import { environment } from './../../../environments/environment';
import {
    AuthService,
    RefreshToken,
} from 'src/app/shared/services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpHeaders,
    HttpContextToken,
    HttpContext,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, from, lastValueFrom, map, switchMap, tap, throwError } from 'rxjs';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { Router } from '@angular/router';

const TOKEN_HEADER_KEY = 'Authorization'; // for Spring Boot back-end
const COMPANY_KEY = 'company-id';
const COMPANY_LOCATION_KEY = 'company-location-id';

const isRequireToken = new HttpContextToken<boolean>(() => true);
const isRequireCompany = new HttpContextToken<boolean>(() => true);

export function tcRequireToken(isRequireTokenParam: boolean = true) {
    return new HttpContext().set(isRequireToken, isRequireTokenParam);
}

export function tcRequireCompany(isRequireCompanyParam: boolean = true) {
    return new HttpContext().set(isRequireCompany, isRequireCompanyParam);
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private contextService: ContextService,
        private authService: AuthService,
        private notificationService: NotificationsService,
        private router: Router
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.context.get(isRequireToken)) {
            let handleReq = from(this.handle(req, next));
            handleReq.subscribe(async (e: any) => {
                if (e.status == 401) {
                    await this.authService.signout(this.notificationService.getDeviceId());
                    this.contextService.signOut();
                    return;
                }
            })
            return handleReq;
        }

        return next.handle(req);
    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        let authReq = req;
        let token = await this.authService.getAccessToken();
        if (req.url.toLowerCase().includes(environment.apiUrl)) {
            if (token != null) {
                if (req.context.get(isRequireCompany)) {
                    this.contextService.getInitPromise();
                    if (!this.contextService.validateSelectedCompany()) {
                        throw new Error(
                            'Select a valid company while calling ' + req.url
                        );
                    }
                }
                const headers = new HttpHeaders({
                    Authorization: 'Bearer ' + token,
                    'x-company-id': this.contextService.getCompanyId().toString(),
                    // 'x-company-location-id':
                    //     this.contextService.getCompanyLocationId().toString() ??
                    //     '',
                });
                authReq = req.clone({ headers });
            } else {
                throw new Error(
                    'Invalid access token while calling ' + req.url
                );
            }
        }

        return await lastValueFrom(next.handle(authReq).pipe(
            map((event: any) => {
                return event;
            }),
            catchError((response: HttpErrorResponse, request) => {
                switch (response.status) {
                    case 403:
                        if (response.error.error.subCode == 105) {
                            this.contextService.isAccountActive$.next(false);
                            this.router.navigateByUrl('deactivated-account-subscription');
                        }
                        break;
                }
                return throwError(response);
            })
        ))
    }
}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
