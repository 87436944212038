<div class="tc-table">
    <div class="table-header" *ngIf="dataTableConfig?.allowTableHeader">
        <div class="actions-section">
            <div class="table-head-buttons">
                <div class="settings" *ngIf="false">
                    <button class="secondary-button-large" id="tc-settings" data-bs-toggle="dropdown"
                        (click)="showFilterBox = false" aria-expanded="false">
                        <img src="/assets/images/table-settings-icon.svg" />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right settings-dropdown" aria-labelledby="tc-settings">
                        <div class="title heading-6" onclick="event.stopPropagation()">
                            {{ "TABLE_SETTINGS" | translate }}
                        </div>
                        <div class="show-columns dropdown" onclick="event.stopPropagation()">
                            <div class="btn-group dropstart">
                                <button type="button" class="secondary-button-large" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <img src="assets/images/arrow-right-black.svg" />
                                    <span>{{ "COLUMNS" | translate }}</span>
                                </button>
                                <ul class="dropdown-menu" *ngIf="dataTableConfig && dataTableConfig?.column">
                                    <ng-container *ngFor="
                                            let column of dataTableConfig?.column;
                                            let i = index
                                        ">
                                        <li *ngIf="
                                                column.type !=
                                                    columnTypeEnum.action &&
                                                column.type !=
                                                    columnTypeEnum.actionDirect &&
                                                i != 0 &&
                                                !column.hide
                                            ">
                                            <a class="dropdown-item">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="visible-column-{{
                                                            i
                                                        }}" [(ngModel)]="
                                                            column.visible
                                                        " />
                                                    <label class="form-check-label body-short-1" for="visible-column-{{
                                                            i
                                                        }}">{{
                                                        column.label
                                                        | translate
                                                        }}</label>
                                                </div>
                                            </a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="row table-settings-action">
                            <div class="col-6">
                                <button class="primary-button-large" (click)="saveSettings()">{{"SAVE"
                                    |translate}}</button>
                            </div>
                            <div class="col-6">
                                <button class="secondary-button-large w-100" (click)="resetSettings()">{{"RESET"
                                    |translate}}</button>
                            </div>
                        </div> -->
                    </ul>
                </div>
                <div class="" *ngIf="false">
                    <button class="secondary-button-large" id="tc-refresh-table" (click)="refresh()" placement="top"
                        ngbPopover="{{'REFRESH_DATA' |translate}}" style="padding-top: 0.4rem;">
                        <i class="icon-refresh-cw-02 icon-s"></i>
                    </button>
                </div>
                <!-- <div class="header-actions"
                    *ngIf="dataTableConfig && dataTableConfig.tableHeaderActions && dataTableConfig.tableHeaderActions.length > 1">
                    <div class="table-actions">
                        <div class="table-actions-list">
                            <button class="secondary-button-large" [matMenuTriggerFor]="menu" id="tc-header-actions">
                                <img src="/assets/images/dots.svg" />
                            </button>
                            <mat-menu #menu="matMenu" xPosition="before">
                                <button mat-menu-item class="body-short-2"
                                    [class.mat-action-disabled]="action.actionDisabled"
                                    *ngFor="let action of dataTableConfig?.tableHeaderActions" (click)="
                                    callTableHeaderAction(action)">
                                    <img class="drop-down-item-icon" src="/assets/images/{{action.icon}}" />
                                    <span class="action-label">{{action.label| translate}} </span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div> -->
                <ng-container *ngIf="dataTableConfig?.addRow">
                    <div class="table-buttons">
                        <button class="primary-button-large add-new" id="add-new-row" (click)="addRow()"
                            *ngIf="dataTableConfig?.addRow">
                            <span>{{
                                dataTableConfig?.addRowLabel ?? "Add New"
                                | translate
                                }}</span>
                        </button>
                    </div>
                </ng-container>
            </div>
            <div class="other-actions">
                <div class="filters">
                    <!-- <div class="row align-items-baseline"> -->
                    <div class="search">
                        <div class="form-group" *ngIf="dataTableConfig?.allowSearch">
                            <input class="body-short-2" placeholder="{{ 'SEAECH_BY_KEYWORD' | translate }}" type="text"
                                [(ngModel)]="searchInput" (ngModelChange)="searchApi()" />
                        </div>
                        <!-- <div class="number-of-selected" *ngIf="selectedItems.length > 0">
                            ({{selectedItems.length}} {{selectedItems.length == 1 ? "row" : "rows"}} selected. <a
                                class="deselect-all" (click)="deselectAll()">Deselect All</a>)
                        </div> -->
                    </div>
                    <!-- </div> -->
                </div>
                <div class="action-template" *ngIf="dataTableConfig && dataTableConfig.tableHeaderAction">
                    <ng-container *ngTemplateOutlet="dataTableConfig.tableHeaderAction">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="data-table gridtable" [class.short-table]="tableData.length < 8" [ngStyle]="{
            height: dataTableConfig?.tableHeight
                ? dataTableConfig?.tableHeight
                : '',
            'min-height': dataTableConfig?.tableHeight
                ? dataTableConfig?.tableHeight
                : ''
        }" *ngIf="showAs == 'list'" [ngClass]="isBusy ? 'wrapper-disabled' : ''" (scroll)="onScroll($event)">
        <table class="table table-striped">
            <thead>
                <tr>
                    <ng-container *ngFor="let item of dataTableConfig?.column">
                        <th *ngIf="item.visible == true" [class.allow-sorting]="
                                dataTableConfig?.allowOrdering &&
                                !item.hideSorting
                            " [ngStyle]="{
                                width: item.width ? item.width : 'auto'
                            }" [class.action-cell]="
                                item.type == columnTypeEnum.action ||
                                item.type == columnTypeEnum.actionDirect
                            ">
                            <ng-container [ngSwitch]="item.type">
                                <ng-container *ngSwitchCase="columnTypeEnum.checkbox">
                                    <div class="form-check">
                                        <mat-checkbox class="example-margin" [checked]="allComplete" color="primary"
                                            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                        </mat-checkbox>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <div class="title" [ngClass]="{
                                            'column-action':
                                                item.type ==
                                                columnTypeEnum.action
                                        }">
                                        <span>
                                            {{ item.label | translate }}
                                        </span>
                                        <!-- <span class="sort" (click)="sort(item)" [ngClass]="{
                                                  'sort-asc': item.sortType == 'asc',
                                                  'sort-desc': item.sortType == 'desc'
                                              }">
                                        </span> -->
                                    </div>
                                </ng-container>
                            </ng-container>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody class="tbody">
                <ng-container *ngIf="!isBusy" [class.d-none]="
                        tableData == undefined || tableData.length == 0
                    ">
                    <tr *ngFor="let item of hackTableData; let y = index">
                        <!-- checkbox select -->
                        <ng-container *ngFor="
                                let column of dataTableConfig?.column;
                                index as x
                            ">
                            <!-- Column Editing  -->
                            <!-- /*********/ -->
                            <td>
                                <div class="h-100 w-100" *ngIf="
                                        activeYEdit == y &&
                                            (activeXEdit
                                                ? x < activeXEdit
                                                : false) &&
                                            column.allowEdit;
                                        else onlyShow
                                    ">
                                    <container-element [ngSwitch]="column.type">
                                        <!-- address -->
                                        <div *ngSwitchCase="
                                                columnTypeEnum.address
                                            " class="h-100" style="width: 99%">
                                            <div class="auto-comp-editable">
                                                <app-autocomplete-address (onAddressChange)="
                                                    onAddressChange(
                                                        $event,
                                                        item,
                                                        column
                                                    )
                                                " id="{{ 'input-' + item.id }}" placeholder="{{
                                                    'ADDRESS_LINE_1' | translate
                                                }}" [filedError]="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                " [localtype]="'fullAddress'">
                                                </app-autocomplete-address>
                                                <div class="validation-msg mb-3" *ngIf="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                ">
                                                    <img src="/assets/images/error-circle-red.svg" />
                                                    <span>{{
                                                        column["errorMessage"]
                                                        }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  -->
                                        <div *ngSwitchCase="
                                                columnTypeEnum.autoComp
                                            " class="h-100" style="width: 99%">
                                            <div class="auto-comp-editable">
                                                <app-autocomplete-dropdown [optionsList]="
                                                        column.optionsList
                                                            ? column.optionsList
                                                            : []
                                                    " [(ngModel)]="
                                                        item[column.key]
                                                    " [id]="item.id" (ngModelChange)="
                                                        selectOption(
                                                            $event,
                                                            column
                                                        )
                                                    " [filedError]="
                                                        !column.valid ||
                                                        checkColumnsErrors(y, x)
                                                    " [isTableEditable]="true"
                                                    (blurInput)="blurInputAutoCompleteDropdown(item)">
                                                </app-autocomplete-dropdown>
                                            </div>
                                            <div class="validation-msg mb-3" *ngIf="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                ">
                                                <img src="/assets/images/error-circle-red.svg" />
                                                <span>{{
                                                    column["errorMessage"]
                                                    }}</span>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="
                                                columnTypeEnum.autoCompApi
                                            " class="h-100" style="width: 99%">
                                            <div class="auto-comp-editable" *ngIf="column.autoCompleteOptions">
                                                <app-autocomplete-dropdown
                                                    [autoCompleteOptions]="column.autoCompleteOptions"
                                                    [customValueLabel]="column.displayText" [(ngModel)]="
                                                        item[column.key]
                                                    " [id]="item.id" (ngModelChange)="
                                                        selectOption(
                                                            $event,
                                                            column
                                                        )
                                                    " [filedError]="
                                                        !column.valid ||
                                                        checkColumnsErrors(y, x)
                                                    " [isTableEditable]="true"
                                                    (blurInput)="blurInputAutoCompleteDropdown(item)">
                                                </app-autocomplete-dropdown>
                                            </div>
                                            <div class="validation-msg mb-3" *ngIf="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                ">
                                                <img src="/assets/images/error-circle-red.svg" />
                                                <span>{{
                                                    column["errorMessage"]
                                                    }}</span>
                                            </div>
                                        </div>
                                        <!-- Date -->
                                        <div *ngSwitchCase="columnTypeEnum.date" class="h-100" style="width: 99%">
                                            <input type="date" class="form-control w-100" id="{{ 'input-' + item.id }}"
                                                (blur)="
                                                    updateValue(item, column)
                                                " (keyup.enter)="
                                                    updateValue(item, column)
                                                " (keydown.Tab)="
                                                    tabEvent(column, x, y)
                                                " [(ngModel)]="item[column.key]" [class.filed-error]="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                " />
                                            <div class="validation-msg mb-3" *ngIf="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                ">
                                                <img src="/assets/images/error-circle-red.svg" />
                                                <span>{{
                                                    column["errorMessage"]
                                                    }}</span>
                                            </div>
                                        </div>
                                        <!--  -->

                                        <!-- Dropdown -->
                                        <div *ngSwitchCase="
                                                columnTypeEnum.dropdown
                                            " class="h-100 dropdown" style="width: 99%">
                                            <app-dropdown [dropdownList]="
                                                    column.optionsList
                                                        ? column.optionsList
                                                        : []
                                                " [(ngModel)]="item[column.key]" [control]="dropdownControl"
                                                [id]="item.id" buttonHeight="2.6rem" (changeValueEvent)="
                                                    selectOption($event, column)
                                                ">
                                            </app-dropdown>
                                            <div class="validation-msg mb-3" *ngIf="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                ">
                                                <img src="/assets/images/error-circle-red.svg" />
                                                <span>{{
                                                    column["errorMessage"]
                                                    }}</span>
                                            </div>
                                        </div>
                                        <!--  -->

                                        <div *ngSwitchCase="columnTypeEnum.inputUnit">
                                            <div class="auto-comp-editable input-unit-editable">
                                                <app-input-unit [(ngModel)]="item[column.key]"
                                                    [id]="'next-maintenance-after-days'"
                                                    [inputUnitType]="column.unitType!"
                                                    [maxlength]="column.maxLength ? column.maxLength : 20">
                                                </app-input-unit>
                                                <div class="validation-msg mb-3" *ngIf="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                ">
                                                    <img src="/assets/images/error-circle-red.svg" />
                                                    <span>{{
                                                        column["errorMessage"]
                                                        }}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <!-- Text,Number Input  -->
                                        <!-- /*********/ -->
                                        <div *ngSwitchDefault class="h-100" style="width: 99%">
                                            <input #editInput [type]="
                                                    column.type ==
                                                    columnTypeEnum.number
                                                        ? 'number'
                                                        : 'text'
                                                " class="form-control w-100" id="{{ 'input-' + item.id }}" [min]="
                                                    column.type ==
                                                    columnTypeEnum.number
                                                        ? 1
                                                        : ''
                                                " (blur)="
                                                    updateValue(item, column)
                                                " (keyup.enter)="
                                                    updateValue(item, column)
                                                " (keydown.Tab)="
                                                    tabEvent(column, x, y)
                                                " [(ngModel)]="item[column.key]" [class.filed-error]="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                " />
                                            <div class="validation-msg mb-3" *ngIf="
                                                    !column.valid ||
                                                    checkColumnsErrors(y, x)
                                                ">
                                                <img src="/assets/images/error-circle-red.svg" />
                                                <span>{{
                                                    column["errorMessage"]
                                                    }}</span>
                                            </div>
                                        </div>
                                        <!-- /*********/ -->
                                    </container-element>
                                </div>
                            </td>

                            <!-- /*********/ -->
                            <ng-template #onlyShow>
                                <div class="validation-msg pt-2" *ngIf="checkColumnsErrors(y, x)">
                                    <img src="/assets/images/error-circle-red.svg" />
                                    <span>{{ column["errorMessage"] }}</span>
                                </div>
                                <td *ngIf="column.visible == true" class="h-100" [ngStyle]="{
                                        width: column.width
                                            ? column.width
                                            : 'auto'
                                    }" [class.action-cell]="
                                        column.type == columnTypeEnum.action
                                    " (click)="callActionColumn(column, item)">
                                    <ng-container [ngSwitch]="column.type">
                                        <!-- checkbox select -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.checkbox">
                                            <div class="form-check">
                                                <mat-checkbox [(ngModel)]="item.isChecked" (ngModelChange)="
                                                        updateAllComplete(item)
                                                    " color="primary">
                                                </mat-checkbox>
                                            </div>
                                        </ng-container>
                                        <!-- Date Label  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="columnTypeEnum.date">
                                            <ng-container *ngIf="
                                                    column.allowStatus &&
                                                        column.statusTemplate;
                                                    else noStatus
                                                ">
                                                <ng-container *ngTemplateOutlet="
                                                        column.statusTemplate;
                                                        context: {
                                                            item: item,
                                                            column: column
                                                        }
                                                    ">
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #noStatus>
                                                <span *ngIf="
                                                        item[column.key];
                                                        else noData
                                                    ">{{
                                                    item[column.key]
                                                    | date : "MM/dd/y"
                                                    }}</span>
                                                <ng-template #noData>
                                                    <span></span>
                                                </ng-template>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Phone Number  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="
                                                columnTypeEnum.phoneNumber
                                            ">
                                            <span *ngIf="
                                                    item[column.key];
                                                    else noData
                                                ">{{
                                                phoneNumberService.phoneNumberFromServer(
                                                item[column.key]
                                                )
                                                }}</span>
                                            <ng-template #noData>
                                                <span></span>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Actions Label  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchCase="
                                                columnTypeEnum.actionDirect
                                            ">
                                            <div *ngIf="
                                                    dataTableConfig?.allowActions
                                                " class="table-actions">
                                                <ng-container *ngIf="
                                                        dataTableConfig?.allowEdit
                                                    ">
                                                    <ng-container *ngIf="
                                                            activeYEdit ==
                                                                null &&
                                                            activeXEdit ==
                                                                null &&
                                                            dataTableConfig?.allowEditRow &&
                                                            !item[column.key]
                                                        ">
                                                        <button class="button-actions" style="padding-top: 5px;" (click)="
                                                                allowEdit(
                                                                    x,
                                                                    y,
                                                                    item
                                                                )
                                                            " title="{{
                                                                'EDIT_ROW'
                                                                    | translate
                                                            }}">
                                                            <i class="icon-edit-02 icon-s-m"></i>
                                                        </button>
                                                    </ng-container>
                                                    <ng-container *ngIf="activeYEdit == y">
                                                        <div class="d-flex">
                                                            <button class="button-actions" (click)="
                                                                    saveInputChanges(
                                                                        item
                                                                    )
                                                                " title="{{
                                                                    'COMMIT'
                                                                        | translate
                                                                }}">
                                                                <i class="icon-check icon-m" style="color: #60AE54;"></i>
                                                            </button>
                                                            <button class="button-actions" *ngIf="
                                                                    item.id != 0
                                                                " (click)="
                                                                    discardInputChanges(
                                                                        item,
                                                                        column
                                                                    )
                                                                " title="{{
                                                                    'DISCARD'
                                                                        | translate
                                                                }}">
                                                                <i class="icon-x icon-lg" style="color: #CB4437;"></i>
                                                            </button>
                                                            <button class="button-actions" *ngIf="
                                                                    item.id == 0
                                                                " (click)="
                                                                    deleteNewRow(item)
                                                                " title="{{
                                                                    'DELETE'
                                                                        | translate
                                                                }}">
                                                                <i class="icon-trash-04 icon-s-m" style="color: #CB4437;"></i>
                                                            </button>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="
                                                columnTypeEnum.action
                                            ">
                                            <div *ngIf="
                                                    dataTableConfig?.allowActions
                                                " class="table-actions">
                                                <ng-container *ngIf="
                                                        dataTableConfig?.actionType ==
                                                        actionTypeEnum.Menu
                                                    ">
                                                    <div class="table-actions-list">
                                                        <img src="/assets/images/{{
                                                                dataTableConfig?.actionIcon
                                                            }}" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                            aria-expanded="false" />
                                                        <ul class="dropdown-menu dropdown-menu-right"
                                                            aria-labelledby="dropdownMenuButton1">
                                                            <li class="dropdown-item body-short-2" [class.d-none]="
                                                                    action.hideAction
                                                                        ? action.hideAction(
                                                                              item
                                                                          )
                                                                        : false
                                                                " *ngFor="
                                                                    let action of dataTableConfig?.actionsList
                                                                " (click)="
                                                                    callAction(
                                                                        action,
                                                                        item
                                                                    )
                                                                ">
                                                                <img class="drop-down-item-icon" *ngIf="
                                                                        action.icon !==
                                                                        undefined
                                                                    " src="/assets/images/{{
                                                                        action.icon
                                                                    }}" />
                                                                <span class="action-label" [class.danger-action]="
                                                                        action.isDanger
                                                                    ">{{
                                                                    action.label
                                                                    | translate
                                                                    }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="
                                                        dataTableConfig?.actionType ==
                                                            actionTypeEnum.Inline &&
                                                        !item[column.key]
                                                    ">
                                                    <div class="inline-actions-icon">
                                                        <ng-container
                                                            *ngIf="activeYEdit == null && activeXEdit == null && dataTableConfig?.allowEditRow && !item[column.key] ">
                                                            <ng-container *ngFor="
                                                                let action of dataTableConfig?.actionsList
                                                            ">
                                                                <span *ngIf="
                                                                    action.actionFunc
                                                                " class="{{
                                                                    action.class
                                                                }}" title="{{
                                                                    action.label
                                                                        | translate
                                                                }}" (click)="
                                                                    action?.actionFunc(
                                                                        item
                                                                    )
                                                                ">
                                                                    {{
                                                                    action.label
                                                                    | translate
                                                                    }}
                                                                </span>
                                                                <span class="{{
                                                                    action.class
                                                                }}" title="{{
                                                                    action.label
                                                                        | translate
                                                                }}" (click)="
                                                                    removeRow(
                                                                        item
                                                                    )
                                                                ">
                                                                    <img src="/assets/images/{{
                                                                        action.icon
                                                                    }}" />
                                                                </span>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <!-- /*********/ -->

                                        <!-- Others Type Label  -->
                                        <!-- /*********/ -->
                                        <ng-container *ngSwitchDefault>
                                            <ng-container *ngIf="
                                                    column.allowStatus &&
                                                        column.statusTemplate;
                                                    else noStatus
                                                ">
                                                <span>
                                                    <ng-container *ngTemplateOutlet="
                                                            column.statusTemplate;
                                                            context: {
                                                                item: item,
                                                                column: column
                                                            }
                                                        ">
                                                    </ng-container>
                                                </span>
                                            </ng-container>
                                            <ng-template #noStatus>
                                                <span>{{
                                                    item[column.key]
                                                    }}</span>
                                            </ng-template>
                                        </ng-container>
                                        <!-- /*********/ -->
                                    </ng-container>
                                </td>
                            </ng-template>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <!-- No Records -->
        <ng-container *ngIf="
                !isBusy && (tableData == undefined || tableData?.length == 0)
            ">
            <div class="no-records no-records-table">
                <div *ngIf="
                        dataTableConfig && dataTableConfig.noRecordTemplate;
                        else noTemplate
                    ">
                    <ng-container *ngTemplateOutlet="dataTableConfig.noRecordTemplate">
                    </ng-container>
                </div>
                <ng-template #noTemplate>
                    <h4 class="heading-4">
                        {{ "NO_RECORDS_FOUND" | translate }}
                    </h4>
                    <p class="body-short-2">
                        {{
                        "YOU_DONOT_HAVE_ANY_DATA_IN_THESE_TABLES"
                        | translate
                        }}
                    </p>
                </ng-template>
            </div>
        </ng-container>
        <!-- Loading -->
        <ng-container *ngIf="isBusy">
            <div class="no-records no-records-table">
                <h3 class="heading-3">{{ "LOADING" | translate }}...</h3>
                <p class="body-short-2">
                    {{ "YOUR_DATA_IS_LOADING_PLEASE_WAIT" | translate }}
                </p>
            </div>
        </ng-container>
        <!--  -->
    </div>
</div>