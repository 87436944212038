import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import {
    MicrosoftLoginProvider,
    SocialAuthService,
    GoogleLoginProvider,
    /*FacebookLoginProvider,*/
} from '@abacritt/angularx-social-login';

import { environment } from 'src/environments/environment';
import { ContextService, Token } from './context.service';
import { AppleLoginProvider } from 'src/app/core/providers/apple-login.provider';
import {
    ApiResponse,
    ApiResponseAny,
} from 'src/app/core/models/api-response.model';
import { Completer } from '../model/shared.model';
import { NotificationsService } from './notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { tcRequireToken } from 'src/app/core/interceptors/auth.interceptor';
import { lastValueFrom } from 'rxjs';

//const AUTH_API = `${environment.webUrl}/auth/`;
const APIURL = `${environment.apiUrl}/`;
const AUTH_APIURL = `${environment.apiUrl}/usr/Authorize/`;

const SOCIAL_APIURL = `${environment.apiUrl}/SocialAuthorize/`;
//const CHATAPIURL = `${environment.apiUrl}/notification/`;

export interface RefreshToken {
    accessToken: string;
    refreshToken: string;
    deviceId: string | null;
    deviceType: string;
    notificationType: string;
    notificationToken: string | null;
}

export interface ResetPasswordResponse {
    emailToken: string;
    resetPasswordToken: string;
    email: string;
    userId: number;
    isUserActivated: boolean;
    verificationResult: ResetPasswordErrors
}

export enum ResetPasswordErrors {
    ResetPasswordExpired = 'ResetPasswordExpired',
    ResetPasswordInvalid = 'ResetPasswordInvalid',
    EmailNotExist = 'EmailNotExist',
    InvalidToken = 'InvalidToken',
    EmptyToken = 'EmptyToken',
}




@Injectable({
    providedIn: 'root',
})
export class AuthService {
    //private searchResult = new BehaviorSubject(null);
    // private searchResult: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    isInsideRefresh: boolean = false;
    public jwtHelper: JwtHelperService = new JwtHelperService();
    userInfo: { userId: string; userName: string; email: string } = {
        userId: '0',
        userName: '',
        email: '',
    };
    searchResult = [];
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }),
        responseType: 'json' as 'json',
    };

    constructor(
        private http: HttpClient,
        public router: Router,
        private spinner: NgxSpinnerService,
        private authSocial: SocialAuthService,
        private contextService: ContextService,
        private notificationsService: NotificationsService
    ) {
        // this.authSocial.authState.subscribe((user) => {
        //     return this.http.post(AUTH_APIURL + 'CarrierSignup', {
        //         userEmail,
        //         userName,
        //         userPassword,
        //         dotNumber
        //     }, {headers: this.reqHeader, responseType: 'text'});
        //     });
    }

    //Carrier Signup Function (POST Method)
    async carrierSignup(
        userEmail: string,
        userName: string,
        userPassword: string,
        dotNumber: number
    ): Promise<ApiResponseAny> {
        return await lastValueFrom(this.http
            .post<ApiResponseAny>(
                AUTH_APIURL + 'CarrierSignup',
                {
                    userEmail,
                    userName,
                    userPassword,
                    dotNumber,
                },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

    //User Signup (POST Method)
    async userSignup(
        firstName: string,
        lastName: string,
        userPassword: string,
        userEmail: string
    ) {
        return await lastValueFrom(this.http
            .post<ApiResponseAny>(
                AUTH_APIURL + 'Signup',
                {
                    firstName,
                    lastName,
                    userPassword,
                    userEmail,
                    source: 'web'
                },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

    async emailExist(email: string): Promise<boolean> {
        let result = await lastValueFrom(this.http
            .get<ApiResponseAny>(AUTH_APIURL + `EmailExist?email=${email}`, {
                ...this.requestOptions,
                context: tcRequireToken(false),
            }));
        if (result.success) {
            return result.data.response;
        } else {
            return false;
        }
    }

    //Varify Email (POST Method)
    async varifyEmail(emailToken: string,companyToken: string | null) {
        return await lastValueFrom(this.http
            .post<ApiResponseAny>(
                AUTH_APIURL + 'VerifyEmail',
                { emailToken,
                    companyToken },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }


    async verifyUserAppEmailToken(emailToken: string,companyToken: string | null) {
        return await lastValueFrom(this.http
            .post<ApiResponseAny>(
                AUTH_APIURL + 'VerifyEmailToken',
                { emailToken,
                    companyToken },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

    //Reset Password (POST Method)
    async resetPassword(emailToken: string, resetPasswordToken: string, newPassword: string) {
        return await lastValueFrom(this.http
            .post<ApiResponseAny>(
                AUTH_APIURL + 'ResetPassword',
                {
                    emailToken,
                    resetPasswordToken,
                    newPassword,
                },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

    //Get a Safer API (Get Method)
    async searchRecords(searchString: string, searchType: any): Promise<any[]> {
        // Convert String into Integer
        searchType = parseInt(searchType);
        //Passing Parameters because in .Net Core we are using query string.
        let params = new HttpParams();
        params = params.append('SearchString', searchString);
        params = params.append('SearchType', searchType);
        //Calling the Safer API and show the results.
        try {
            let result = await lastValueFrom(this.http
                .get<ApiResponseAny>(AUTH_APIURL + 'Safer', {
                    ...this.requestOptions,
                    params: params,
                }));
            if (result.success) return result.data;
            else return [];
        } catch (error: any) {
            // handle error
            return [];
        }
    }

    //Function to Save a Search Results in searchResult Variable
    saveSearchresults(data: []) {
        this.searchResult = data;
    }

    //Function to Retrive a Search Results from the searchResult Variable
    retrievePassedObject() {
        return this.searchResult;
    }

    //Company Claim Ownership (Get Method)
    async checkCompany(dotNumber: number) {
        //Passing Parameters Because in .Net Core we are using Query String.
        let params = new HttpParams();
        params = params.append('dotNumber', dotNumber);
        //Calling the Company API
        return await lastValueFrom(this.http
            .get<ApiResponseAny>(AUTH_APIURL + 'Company', {
                ...this.requestOptions,
                params: params,
            }));
    }

    async registerNotificaiton(): Promise<ApiResponseAny> {
        if (this.contextService.getUserId() && this.notificationsService.getFirebaseNotificationToken() != null) {
            let resp = await lastValueFrom(this.http
                .put<ApiResponseAny>(
                    // APIURL + `Company/${companyId}/Employee/${driverModel.id}`,
                    APIURL + `usr/User/${this.contextService.getUserId()}/UserDevice`,
                    {
                        deviceId: this.notificationsService.getDeviceId(),
                        deviceType: 'WEB',
                        notificationType: 'FCM',
                        notificationToken:
                            this.notificationsService.getFirebaseNotificationToken(),
                    },
                    this.requestOptions
                ));
            return resp;
        } else
            throw new Error('Cannot save notification token');
    }

    //User Signin (Get Method)
    async userSignin(
        userEmail: string,
        password: string
    ): Promise<ApiResponse<Token>> {
        //Calling the Signin API and show the results.
        let result = await lastValueFrom(this.http
            .post<ApiResponse<Token>>(
                AUTH_APIURL + 'Signin',
                {
                    userEmail: userEmail,
                    Password: password,
                    deviceId: this.notificationsService.getDeviceId(),
                    deviceType: 'WEB',
                    notificationType: 'FCM',
                    notificationToken:
                        this.notificationsService.getFirebaseNotificationToken(),
                },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
        // .then((userData) => {
        //     this.userInfo.userId = userData.userId;
        //     this.userInfo.fullName = userData.fullName;
        //     this.userInfo.email = userData.email;
        // });

        if (result.success) return result;
        else
            return {
                success: false,
                data: null,
            };
    }

    //Forgot Password (Get Method)
    async forgotPassword(email: string) {
        let params = new HttpParams();
        params = params.append('email', email);
        params = params.append('source', 'web');
        return await lastValueFrom(this.http
            .get<ApiResponseAny>(AUTH_APIURL + 'ForgotPassword', {
                ...this.requestOptions,
                context: tcRequireToken(false),
                params: params,
            }));
    }

    signOut(): void {
        this.contextService.signOut();
    }

    //Get a List of Users
    async listOfUsers() {
        let result = await lastValueFrom(this.http
            .get<ApiResponseAny>(AUTH_APIURL, this.requestOptions));
        if (result.success) result.data;
    }

    //Get a company Location by the Get Method.
    async companyLocation() {
        const userId = this.contextService.getUserId();
        // let result = await lastValueFrom(this.http.get<ApiResponseAny>(`${APIURL}/CompanyLocation`, this.requestOptions));
        const response = await lastValueFrom(this.http
            .get<ApiResponseAny>(
                // `${APIURL}User/${userId}/Company`,
                `${APIURL}cmp/CompanyAux/FpList`,
                this.requestOptions
            ));
        if (response.success) return response.data;
    }

    get currentUser() {
        let token = this.contextService.getToken();
        if (!token) {
            return null;
        }
        return new JwtHelperService().decodeToken(token);
    }

    async signInWithGoogle(): Promise<boolean> {
        try {
            let googleUserData = await this.authSocial.signIn(
                GoogleLoginProvider.PROVIDER_ID,
                {
                    // oneTapEnabled: false,

                }
            );

            let tcUserData = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    SOCIAL_APIURL + 'Google',
                    {
                        email: googleUserData.email,
                        userId: googleUserData.id,
                        idToken: googleUserData.idToken,
                        authToken: googleUserData.authToken,
                    },
                    { ...this.requestOptions, context: tcRequireToken(false) }
                ));

            // store id token and auth token in local storage
            // Uless google saves it for us

            // save to local storage
            if (tcUserData.success) {
                this.contextService.saveToken(tcUserData.data);

                //  This should be called by the caller
                // let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
                // this.router.navigate([ returnUrl || '/']);

                return true;
            } else {
                // handle error
                return false;
            }
        } catch (error: any) {
            console.log(error);
            // handle error
            return false;
        }
    }

    async signInWithMicrosoft(): Promise<boolean> {
        try {
            let microsoftUserData = await this.authSocial.signIn(
                MicrosoftLoginProvider.PROVIDER_ID
                // {
                //     scopes: ['Mail.Send'],
                //     redirect_uri: 'https://localhost:4200/',
                // }
            );

            console.log(microsoftUserData);

            let tcUserData = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    SOCIAL_APIURL + 'Microsoft',
                    {
                        email: microsoftUserData.email,
                        userId: microsoftUserData.id,
                        idToken: microsoftUserData.idToken,
                        authToken: microsoftUserData.authToken,
                    },
                    { ...this.requestOptions, context: tcRequireToken(false) }
                ));

            // store id token and auth token in local storage
            // Uless google saves it for us

            // save to local storage
            if (tcUserData.success) {
                this.contextService.saveToken(tcUserData.data);

                //  This should be called by the caller
                // let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
                // this.router.navigate([ returnUrl || '/']);

                return true;
            } else {
                // handle error
                return false;
            }
        } catch (error: any) {
            // handle error
            return false;
        }
    }

    async signInWithApple(): Promise<boolean> {
        try {
            let appleUserData = await this.authSocial.signIn(
                AppleLoginProvider.PROVIDER_ID
            );

            let tcUserData = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    SOCIAL_APIURL + 'Apple',
                    {
                        firstName: appleUserData.firstName,
                        lastName: appleUserData.lastName,
                        token: appleUserData.idToken,
                    },
                    this.requestOptions
                ));

            // store id token and auth token in local storage
            // Uless google saves it for us

            if (tcUserData.success) {
                // save to local storage
                this.contextService.saveToken(tcUserData.data);

                //  This should be called by the caller
                // let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
                // this.router.navigate([ returnUrl || '/']);
                return true;
            } else {
                // handle error
                return false;
            }
        } catch (error: any) {
            // handle error
            return false;
        }
    }

    //   signInWithFB(): void {
    //     this.authSocial.signIn(FacebookLoginProvider.PROVIDER_ID);
    //   }

    refreshGoogleToken(): void {
        this.authSocial.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    }

    // Refresh Token
    async refreshTokenApi(body: RefreshToken): Promise<ApiResponse<Token>> {
        let result = await lastValueFrom(this.http
            .post<ApiResponse<Token>>(AUTH_APIURL + 'RefreshToken', body, {
                ...this.requestOptions,
                context: tcRequireToken(false),
            }));
        if (result.success) {
            return result;
        } else
            return {
                success: false,
                data: null,
            };
    }

    // Signout
    async signout(
        deviceId: string | null
    ): Promise<ApiResponse<ApiResponseAny>> {
        try {
            this.spinner.show();
            let result = await lastValueFrom(this.http
                .post<ApiResponse<ApiResponseAny>>(
                    AUTH_APIURL + 'Signout',
                    {
                        accessToken: this.contextService.getToken(),
                        refreshToken: this.contextService.getRefreshToken(),
                        deviceId: deviceId,
                    },
                    this.requestOptions
                ));

            if (result.success) {
                return result;
            } else
                return {
                    success: false,
                    data: null,
                };
        } catch (err: any) {
            return {
                success: false,
                data: null,
            };
        } finally {
            this.spinner.hide();
        }
    }

    isInsideRefreshNew: Promise<boolean> | null = null;

    async getAccessToken() {
        if (this.isInsideRefreshNew != null) {
            let success = await this.isInsideRefreshNew;
            if (success) {
                return this.contextService.getToken();
            } else {
                return null;
            }
        } else {
            const prom = new Completer<boolean>();
            this.isInsideRefreshNew = prom.promise;

            let token = this.contextService.getToken();
            if (token != null) {
                let t = new Date();
                t.setSeconds(t.getSeconds() + 10);
                let expiryDate = this.jwtHelper.getTokenExpirationDate(token);
                if (expiryDate == null || expiryDate < t) {
                    this.isInsideRefresh = true;
                    await this.refresh();
                    token = this.contextService.getToken();
                }
            }
            prom.complete(true);
            this.isInsideRefreshNew = null;
            return token;
        }
    }

    async refresh(): Promise<boolean> {
        let token = this.contextService.getToken();
        const isRefreshSuccess = await this.refreshingTokens(token);
        if (!isRefreshSuccess) {
            this.contextService.signOut();
            return false;
        }
        return true;
    }

    public async refreshingTokens(token?: string | null): Promise<boolean> {
        const refreshToken: string | null =
            this.contextService.getRefreshToken();

        if (!token || !refreshToken) {
            return false;
        }

        let isRefreshSuccess: boolean;
        let body: RefreshToken = {
            accessToken: this.contextService.getToken() ?? '',
            refreshToken: refreshToken ?? '',
            deviceId: this.notificationsService.getDeviceId(),
            deviceType: 'WEB',
            notificationType: 'FCM',
            notificationToken:
                this.notificationsService.getFirebaseNotificationToken(),
        };
        try {
            const response = await this.refreshTokenApi(body);
            if (response.data) {
                this.contextService.setToken(response.data.token);
                this.contextService.setRefreshToken(response.data.refreshToken);
                this.notificationsService.getFirebaseToken();
                isRefreshSuccess = true;
            } else {
                isRefreshSuccess = false;
            }
        } catch (ex) {
            isRefreshSuccess = false;
        }

        return isRefreshSuccess;
    }

    async isLoggedIn(): Promise<boolean> {
        let token = this.contextService.getToken();
        if (!token || token == undefined) {
            return false;
        }
        let jwtHelper = new JwtHelperService();
        let expirationDate = jwtHelper.getTokenExpirationDate(token);
        let isExpired = jwtHelper.isTokenExpired(token);
        if (isExpired) {
            await this.getAccessToken();
            token = this.contextService.getToken();
            if (token) isExpired = jwtHelper.isTokenExpired(token);
        }
        return !isExpired;
    }

    async resetPasswordVerifyToken(emailToken: string, resetPasswordToken: string) {
        return await lastValueFrom(this.http
            .post<ApiResponse<ResetPasswordResponse>>(
                AUTH_APIURL + 'ResetPasswordVerifyToken',
                {
                    emailToken,
                    resetPasswordToken
                },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

}
