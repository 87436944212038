import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'app-driver-app-verify-email',
    templateUrl: './driver-app-verify-email.component.html',
    styleUrls: ['./driver-app-verify-email.component.scss']
})
export class DriverAppVerifyEmailComponent implements OnInit {

    constructor(
        public platform: Platform,
        private route: ActivatedRoute,
        private authService: AuthService,
        private spinnerService: NgxSpinnerService
    ) { }
    link: string = "";
    isDesktop: boolean = false;
    tokenValid: boolean = true;
    emailToken: any;
    isBusy: number = 0;

    ngOnInit(): void {
        this.emailToken = this.route.queryParams.pipe(filter((params) => params.d));
        this.emailToken = this.emailToken.destination.value.d;
        this.verifyUserAppEmailToken();
    }

    async verifyUserAppEmailToken() {
        try {
            this.isBusy++;
            this.spinnerService.show();
            let response = await this.authService.verifyUserAppEmailToken(this.emailToken, null);
            if (response.success) {
                if (response.data?.verificationResult == "Valid" || response.data?.verificationResult == "EmailAlreadyVerified") {
                    if (response.data?.verificationResult == "Valid") {
                        await this.varifyEmail();
                    }
                } else {
                    this.tokenValid = false;
                }
            }
        } catch (error) {
            this.tokenValid = false;
        } finally {
            this.isBusy--;
            this.spinnerService.hide();
        }
    }

    async varifyEmail() {
        try {
            this.isBusy++;
            this.spinnerService.show();
            let response = await this.authService.varifyEmail(this.emailToken, null);
            if (response.success) {
                this.tokenValid = true;
                this.link = window.location.href;
                if (this.platform.IOS) {
                    setTimeout(() => {
                        window.location.href = "https://apps.apple.com/app/truckin-central/id6443468920"
                    }, 4000);
                } else if (this.platform.ANDROID) {
                    setTimeout(() => {
                        window.location.href = 'https://play.google.com/store/apps/details?id=com.truckincentral.driver';
                    }, 4000);
                } else {
                    this.isDesktop = true;
                }
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        } finally {
            this.isBusy--;
            this.spinnerService.hide();
        }
    }

    contactSupport() {
        window.location.href = "mailto:support@truckincentral.com";
    }

}
