import { data } from 'jquery';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

const APIURL = `${environment.apiUrl}/`;

export interface AppVersion {
    latestVersion: string
    isMandatoryUpdate: boolean
    isUpToDate: boolean
}

@Injectable({
    providedIn: 'root'
})
export class AppVersionService {

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    async getAppVersion(): Promise<ApiResponse<AppVersion>> {
        let response = await lastValueFrom(
            this.http.get<ApiResponse<AppVersion>>(APIURL + `AppVersion?currentVersion=${environment.appVersion}&deviceType=WEB`));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null
            }
        }
    }

    async checkAppVersion() {
        let reloadCounter = Number(window.localStorage.getItem('check-version-count'));;
        if (reloadCounter == undefined) {
            window.localStorage.setItem('check-version-count', '0');
        }
        try {
            let response = await this.getAppVersion();
            if (response.success && response.data) {
                if (!response.data.isUpToDate) {
                    if (response.data.isMandatoryUpdate) {
                        reloadCounter++;
                        window.localStorage.setItem('check-version-count', reloadCounter.toString());
                        if (reloadCounter > 3) {
                            this.router.navigate(['/maintenance-mode']);
                        } else {
                            window.location.reload();
                        }
                    }
                }
            }

        } catch (error) {

        } finally {

        }
    }
}
