<div class="tc-dropdown">
    <p-dropdown appendTo="body" [options]="filteredItems" [formControl]="myControl" optionLabel="text"
        [showClear]="false" (click)="lodaingMessage()" placeholder="{{placeholder | translate}}"
        (onChange)="onChangeValue($event)">
        <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="myControl.value">
                <div>{{ myControl.value['text'] }}</div>
            </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
            <div>
                <div class="heading-7">{{ item.text }}</div>
                <div class="label-1" [innerHTML]="item.description"></div>
            </div>
        </ng-template>
    </p-dropdown>

</div>