import { data } from 'jquery';
import { SharedService } from 'src/app/shared/services/shared.service';
import { tcRequireCompany } from 'src/app/core/interceptors/auth.interceptor';
import { FeatureService } from './feature.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import {
    ApiResponse,
    ApiResponseAny,
    GetApiResponse,
    PutApiResponse,
} from 'src/app/core/models/api-response.model';
import { EmployeeCompany } from 'src/app/core/models/company.model';
import { Completer } from '../model/shared.model';
import { PermissionsService } from './permissions.service';
import { RoleSysApiService } from 'src/app/modules/roles-and-permissions-system/services/role-sys-api.service';
import { Platform } from '@angular/cdk/platform';
import { debug } from 'console';

const USER_KEY = 'auth-user-id';
const USERNAME_KEY = 'auth-user-name';
const COMPANY_KEY = 'company-id';
const COMPANY_LOCATION_KEY = 'company-location-id';
const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN = 'refresh-token';
const REFRESHEXPIRESIN = 'refreshExpiresIn';
const APP_MENU_OPEND_KEY = 'menu-opend';
const EMAIL_KEY = 'email';
const COMPANY_USER_ID = 'company-user-id';
const EMAIL_COMPANY = 'email-company'

export interface Token {
    token: string;
    userID: string;
    fullName: string;
    refreshToken: string;
    refreshExpiresIn: string;
    deviceId: string;
    userEmail: string
}

export interface DemoData {
    id: number
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    companyName: string | null;
    dotNumber: string;
    mcNumber: string
    addressLine1: string
    addressLine2: string
    city: string
    stateCode: string
    zipCode: string
    numberOfTrucks: number
    numberOfDrivers: number
    fleetSize: string | null;
    scheduledTestDate: Date | string;
    isDemoRequested?: boolean;
    termsAndConditions?: boolean;
}

interface Appointment {
    email: string;
    companyName: string;
}
// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };

@Injectable({
    providedIn: 'root',
})
export class ContextService {
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'json',
        }),
        responseType: 'json' as 'json',
    };

    private initializatingContext: Promise<boolean> | null = null;
    companyName$ = new BehaviorSubject<string>('Loading companies ...');
    companyLogoId$ = new BehaviorSubject<number>(0);
    isSetupCompleted$ = new BehaviorSubject<boolean>(true);
    isCompaniesLoadedOnce = false;
    employeeCompanies$: BehaviorSubject<EmployeeCompany[]> =
        new BehaviorSubject<EmployeeCompany[]>([]);
    companyId$ = new BehaviorSubject<number>(0);
    loggedInUserId$ = new BehaviorSubject<number>(0);
    isShowPopoverHelp$ = new BehaviorSubject<boolean>(false);
    isPaymentMethodSet$ = new BehaviorSubject<boolean>(true);
    isThereUnpaidInvoice$ = new BehaviorSubject<boolean>(false);
    isAccountActive$ = new BehaviorSubject<boolean>(true);

    constructor(
        private httpClient: HttpClient,
        private platform: Platform,
        private spinner: NgxSpinnerService,
        public router: Router,
        private featureService: FeatureService,
        private shared: SharedService,
        private permissionService: PermissionsService,
        private roleSysApiService: RoleSysApiService
    ) {
        //this.reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    }

    // private oldUserId = 0;

    getInitPromise() {
        if (!this.initializatingContext)
            throw new Error('Context is not initialized');
        return this.initializatingContext;
    }

    async updateCompanyId(companyId: number) {
        if (companyId !== this.companyId$.getValue()) {
            this.companyId$.next(companyId);
            let result = await lastValueFrom(
                this.httpClient.get<ApiResponseAny>(
                    `${environment.apiUrl}/cmp/UserAux/${this.getUserId()}/Employee?companyId=${this.getCompanyId()}`,
                    { ...this.requestOptions }
                )
            );
            if (result.success) {
                window.localStorage.setItem(COMPANY_USER_ID, result.data.companyUserId);
            }

        }
    }

    updateSetupStatus(isSetupCompleted: boolean) {
        this.isSetupCompleted$.next(isSetupCompleted);
    }

    public async rereadAllCompanies(userId: number = 0) {
        userId = userId > 0 ? userId : this.loggedInUserId$.getValue();
        let employeeCompanies = userId > 0 ? await this.getEmployeeCompanies() : [];
        this.employeeCompanies$!.next(employeeCompanies);
        if (employeeCompanies.length == 1) {
            this.selectCompany(employeeCompanies[0].id);
        } else if (employeeCompanies.length == 0) {
            await this.checkSysAdmin();
        }
        // await this.updateCompaniesInfo(this.getCompanyId() ?? 0);
        let currCompanyId = this.getCompanyId();
        this.selectCompany(currCompanyId ?? 0);
        // this.formatCompanyName();
        this.companyName$.next(
            employeeCompanies!.find((company) => company.id == currCompanyId)
                ?.name ?? 'Select Company'
        );
        this.companyLogoId$.next(
            employeeCompanies!.find((company) => company.id == currCompanyId)
                ?.logoDocumentId ?? 0
        );
        let companyEmail = employeeCompanies!.find((company) => company.id == currCompanyId)?.email ?? '';
        this.setCompanyEmail(companyEmail)
    }

    async checkSysAdmin() {
        try {
            let response =
                await this.roleSysApiService.getAuthorizePermission();
            if (response.success && response.data) {
                if (response.data.userResourcesPermissions.length > 0) {
                    this.setSysAdmin('true');
                } else {
                    this.setSysAdmin('false');
                }
            } else {
                this.setSysAdmin('false');
            }
        } catch (error) {
            this.setSysAdmin('false');
        }
    }

    isMoreThanOneCompany(): boolean {
        let employeeCompanies = this.employeeCompanies$.getValue();
        return employeeCompanies.length > 1;
    }

    public getCompanyInitials(): string {
        return (
            this.companyName$
                .getValue()
                .match(/(\b\S)?/g)
                ?.join('')
                .match(/(^\S|\S$)?/g)
                ?.join('')
                .toUpperCase() ?? ''
        );
    }

    //#region Token
    async saveToken(token: Token) {
        let oldUserId = this.getUserId();
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(REFRESHTOKEN);
        window.localStorage.removeItem(REFRESHEXPIRESIN);
        this.setToken(token.token);
        this.setRefreshToken(token.refreshToken);
        window.localStorage.setItem(REFRESHEXPIRESIN, token.refreshExpiresIn);
        window.localStorage.setItem(USER_KEY, token.userID);
        window.localStorage.setItem(USERNAME_KEY, token.fullName);
        window.localStorage.setItem(EMAIL_KEY, token.userEmail);
        if (oldUserId !== +token.userID)
            this.loggedInUserId$.next(+token.userID);
    }

    public getToken(): string | null {
        return window.localStorage.getItem(TOKEN_KEY);
    }

    public getEmail(): string | null {
        return window.localStorage.getItem(EMAIL_KEY);
    }

    public getRefreshToken(): string | null {
        return window.localStorage.getItem(REFRESHTOKEN);
    }

    public setToken(token: string) {
        window.localStorage.setItem(TOKEN_KEY, token);
    }

    public setRefreshToken(refreshToken: string) {
        window.localStorage.setItem(REFRESHTOKEN, refreshToken);
    }
    //#endregion Token

    public validateUserId(userId: number): void {
        if (this.getUserId() != userId) {
            // handle error
        }
    }
    // tobeDeleted
    // async getCompanyLocationInfo(
    //     companyId: number,
    //     companyLocationId: number
    // ): Promise<ApiResponse<CompanyLocation>> {
    //     let response = await lastValueFrom(this.httpClient
    //         .get<ApiResponse<CompanyLocation>>(
    //             `${environment.apiUrl}/CompanyLocation/${companyId}/${companyLocationId}`
    //         )
    //         );
    //     return response;
    // }

    signOut() {
        let deviceId: string = localStorage.getItem('device-id') ?? '';
        let gridsterItems: string = localStorage.getItem('gridster-items') ?? '';
        let apiUrl: string | null = localStorage.getItem('api-url') ?? null;
        this.loggedInUserId$.next(0);
        this.updateCompanyId(0);
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.permissionService.resetPermissions();
        // window.location.reload();
        this.featureService.restFeatures();
        localStorage.setItem('device-id', deviceId);
        localStorage.setItem('gridster-items', gridsterItems);
        if (apiUrl)
            localStorage.setItem('api-url', apiUrl);
        this.router.navigate(['/auth/login']);
    }

    // toBeDeleted
    // public getCompanyLocationId(): number {
    //     return Number(window.localStorage.getItem(COMPANY_LOCATION_KEY));
    // }

    async deleteDemo(appointment: Appointment): Promise<ApiResponseAny> {
        // let obj = {};
        // obj['email'] = appointment.email;
        // obj['companyName'] = appointment.companyName;

        return await lastValueFrom(
            this.httpClient.delete<ApiResponseAny>(
                `${environment.apiUrl}/Demo`,
                {
                    ...this.requestOptions,
                    context: tcRequireCompany(false),
                }
            )
        );
    }

    validateSelectedCompany(): boolean {
        let employeeCompanies = this.employeeCompanies$.getValue();
        if (this.isSysAdmin()) {
            return true;
        }
        if (!(this.getCompanyId() && employeeCompanies)) {
            return false;
        }
        return true;
    }

    public async init() {
        const completer = new Completer<boolean>();
        this.initializatingContext = completer.promise;

        let userId = this.getUserId();
        this.loggedInUserId$.next(userId);

        this.updateCompanyId(this.getCompanyId());

        if (userId > 0) {
            if (window.localStorage.getItem(APP_MENU_OPEND_KEY) == null) {
                window.localStorage.setItem(APP_MENU_OPEND_KEY, 'true');
                this.shared.menuOpend$.next(true);
            }
            await this.rereadAllCompanies(userId);
            await this.featureService.getAllFeatures();
            if (this.getCompanyId() > 0 || this.isSysAdmin())
                await this.permissionService.getAllUserPermissions();
            if (this.getCompanyId() > 0) {
                await this.isPaymentMethodSet();
                await this.isThereUnpaidInvoices();
            }
        }
        completer.complete(true);
    }

    public async getEmployeeCompanies(): Promise<EmployeeCompany[]> {
        try {
            let result = await lastValueFrom(
                this.httpClient.get<ApiResponse<EmployeeCompany[]>>(
                    // `${environment.apiUrl}/User/${1}/Company`,

                    `${environment.apiUrl}/cmp/CompanyAux/FpList`,
                    { ...this.requestOptions, context: tcRequireCompany(false) }
                )
            );
            if (result.success) {
                this.isCompaniesLoadedOnce = true;
                if (result.data) {
                    this.employeeCompanies$.next(result.data);
                    return result.data;
                }
            }
            return [];
        } catch (err) {
            return [];
        } finally {
        }
    }

    getUserName(): string {
        return window.localStorage.getItem(USERNAME_KEY) ?? '';
    }

    //#region User
    public getUserId(): number {
        return Number(window.localStorage.getItem(USER_KEY));
    }

    public getCompanyUserId(): number {
        return Number(window.localStorage.getItem(COMPANY_USER_ID));
    }

    //Function to Select a company When User Chooses one from the Navbar
    async selectCompany(companyId: number) {
        if (this.getCompanyId() !== companyId || companyId == 0) {
            window.localStorage.removeItem(COMPANY_KEY);
            window.localStorage.removeItem(COMPANY_LOCATION_KEY);
            window.localStorage.setItem(COMPANY_KEY, String(companyId));
            window.localStorage.setItem(
                COMPANY_LOCATION_KEY,
                String(companyId)
            );
            let companies = this.employeeCompanies$.getValue();
            if (!this.isSysAdmin() &&
                companyId > 0 &&
                (companies.length > 0 &&
                    companies.find((el) => el.id == companyId)!
                        .isSetupComplete == false)
            ) {
                this.router.navigateByUrl('carrier-setup');
            }
            this.updateCompanyId(companyId);
            // window.location.reload();
            // this.router.navigate(['home']);
        }
        if (companyId || this.getCompanyId()) {
            await this.isPaymentMethodSet();
            await this.isThereUnpaidInvoices();
        }
    }

    getCompanyId(): number {
        // let locId = this.getCompanyLocationId();
        // return this.getCompanyIdFromLocation(locId);
        return Number(window.localStorage.getItem(COMPANY_KEY));
    }

    async isPaymentMethodSet() {
        try {
            let response = await this.isPymentMethodRequired();
            if (response.success && response.data == true) {
                this.isPaymentMethodSet$.next(false);
            } else {
                this.isPaymentMethodSet$.next(true);
            }
        } catch (error) {
            this.isPaymentMethodSet$.next(false);
        }
    }
    async isThereUnpaidInvoices() {
        try {
            let response = await this.unpaidInvoicesCount();
            if (response.success && response.data == true || response.data.count > 0) {
                this.isThereUnpaidInvoice$.next(true);
            } else {
                this.isThereUnpaidInvoice$.next(false);
            }
        } catch (error) {
            this.isThereUnpaidInvoice$.next(false);
        }
    }

    // private getCompanyIdFromLocation(companyId: number): number {
    //     const companies = this.employeeCompanies$.getValue();
    //     const slelectedCompany = companies.find(company => company.id == companyId)
    //     return companies?.companyId ?? 0;
    // }

    //LoggedIn Function is Used to check if User Loggedin or not! and add the Restriction on the User
    // async isLoggedIn(): Promise<boolean> {
    //     let token = this.getToken();
    //     if (!token || token == undefined) {
    //         return false;
    //     }
    //     let jwtHelper = new JwtHelperService();
    //     let expirationDate = jwtHelper.getTokenExpirationDate(token);
    //     let isExpired = jwtHelper.isTokenExpired(token);
    //     if (isExpired) {
    //         const auth = this.injector.get<AuthService>(AuthService);
    //         await auth.getAccessToken();
    //         token = this.getToken();
    //         if (token)
    //             isExpired = jwtHelper.isTokenExpired(token);
    //     }
    //     return !isExpired;
    // }

    // private async updateCompaniesInfo(companyId: number) {
    //     if (companyId > 0) {
    //         let employeeCompanies = this.employeeCompanies$!.getValue();
    //         // it seems that this is called while it is not bound yet
    //         const selectedCompany = employeeCompanies.find(
    //             (company) => company.companyId == companyId
    //         );
    //         this.selectCompany(companyId ?? 0);
    //         // Auto select a company
    //         // if (this.isOnlyOneLocation()) {
    //         // }

    //         // These will handle the following use case:
    //         //  We already have a company location selected but this function is called
    //         //  to refresh available companies
    //         // this.formatCompanyName();
    //         // } else {
    //         // When no companies are available, we should reset the company to Select Company
    //         //this.formatCompanyName();
    //         // }
    //     }
    // }

    // isOnlyOneLocation(): boolean {
    //     let companies = this.companyUserEmployee$.getValue();
    //     return (
    //         companies.length == 1 &&
    //         companies[0].employeeCompanyLocations.length == 1
    //     );
    // }

    // private formatCompanyName() {
    // // if (!this.isCompaniesLoadedOnce) return;
    // let currCompanyId = this.getCompanyId();
    // let companyUserEmployee = this.employeeCompanies$.getValue();
    // // format the name of the company/location

    // this.companyName$.next(
    //     companyUserEmployee!.find(
    //         (company) => company.companyId == currCompanyId
    //     )?.companyName ?? 'Select Company'
    // );
    // console.log('format company name', companyUserEmployee!.find(
    //     (company) => company.companyId == currCompanyId
    // )?.companyName ?? '')

    // for (let company of companies) {
    //     if (
    //         company.employeeCompanyLocations.length === 1 &&
    //         company.employeeCompanyLocations[0].companyLocationID ===
    //         currCompanyLocationId
    //     ) {
    //         this.companyLocationName$.next(company.companyName);
    //         return;
    //     } else {
    //         for (let location of company.employeeCompanyLocations) {
    //             if (location.companyLocationID === currCompanyLocationId) {
    //                 this.companyLocationName$.next(
    //                     <string>company.companyName +
    //                     ' - ' +
    //                     location.locationName
    //                 );
    //                 return;
    //             }
    //         }
    //     }
    // }
    // }

    setSysAdmin(val: string) {
        window.localStorage.setItem('system-admin', val);
    }

    isSysAdmin(): boolean {
        return window.localStorage.getItem('system-admin') == 'true'
            ? true
            : false;
    }

    detectDeviceInfo() {
        let deviceInfo = {
            browserType: '',
            osType: '',
            deviceType: '',
        };

        const userAgent = navigator.userAgent;
        const platform = navigator.platform;

        /**Detect browser */
        if (userAgent.indexOf('Chrome') > -1) {
            deviceInfo.browserType = 'Google Chrome';
        } else if (userAgent.indexOf('Firefox') > -1) {
            deviceInfo.browserType = 'Mozilla Firefox';
        } else if (userAgent.indexOf('Safari') > -1) {
            deviceInfo.browserType = 'Apple Safari';
        } else if (userAgent.indexOf('Opera') > -1) {
            deviceInfo.browserType = 'Opera';
        } else if (userAgent.indexOf('Edge') > -1) {
            deviceInfo.browserType = 'Microsoft Edge';
        } else if (userAgent.indexOf('Trident') > -1) {
            deviceInfo.browserType = 'Internet Explorer';
        } else {
            deviceInfo.browserType = 'Unknown';
        }

        /**Detect OS */
        if (platform.indexOf('Win') > -1) {
            deviceInfo.osType = 'Windows';
        } else if (platform.indexOf('Mac') > -1) {
            deviceInfo.osType = 'Macintosh';
        } else if (platform.indexOf('Linux') > -1) {
            deviceInfo.osType = 'Linux';
        } else if (
            platform.indexOf('iPhone') > -1 ||
            platform.indexOf('iPad') > -1 ||
            platform.indexOf('iPod') > -1
        ) {
            deviceInfo.osType = 'iOS';
        } else if (platform.indexOf('Android') > -1) {
            deviceInfo.osType = 'Android';
        } else {
            deviceInfo.osType = 'Unknown';
        }

        /** Detect device type */
        if (this.platform.isBrowser) {
            const screenWidth = window.innerWidth;

            if (screenWidth < 768) {
                deviceInfo.deviceType = 'Mobile';
            } else if (screenWidth >= 768 && screenWidth < 1024) {
                deviceInfo.deviceType = 'Tablet';
            } else {
                deviceInfo.deviceType = 'Desktop';
            }
        } else {
            deviceInfo.deviceType = 'Unknown';
        }

        return deviceInfo;
    }

    setCompanyEmail(value: string) {
        window.localStorage.setItem(EMAIL_COMPANY, value);
    }

    getCompanyEmail() {
        return window.localStorage.getItem(EMAIL_COMPANY);
    }

    async isPymentMethodRequired(): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.httpClient
            .get<ApiResponse<ApiResponseAny>>(
                `${environment.apiUrl}/cmp/BillingSettings/IsPaymentMethodRequired`
            ));
        return response;
    }
    async unpaidInvoicesCount(): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.httpClient
            .get<ApiResponse<ApiResponseAny>>(
                `${environment.apiUrl}/cmp/BillingSettings/Invoices/UnpaidCount`
            ));
        return response;
    }

}
